<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>企业自助认款</el-breadcrumb-item>
              <el-breadcrumb-item>认款申请</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rightsd">
                <div class="box_right_top">企业自助认款</div>
                <div class="box_right_cent">
                    <div class="firm-box">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="提交认款申请单" name="first">
                              <div>认款信息完整的情况下，我们会在工作日24小时内完成认款审核工作。</div>
                              <div>1)自助认款服务仅面向线下汇款的企业用户。</div> 
                              <div>2)支持还金采，一款一单，一款多单，支持充余额操作。</div>
                              <div>3)如列表中无所需订单，可能在本账号或相关账号的已生成申请单中。</div>
                              <div class="firm-top">
                                <div style="position: relative;">
                                  <div class="frim-line"></div>
                                  <div class="firm-select">
                                    <div class="firm-number">1</div>
                                    <div class="firm-text">选择汇款用途</div>
                                  </div>
                                  <div class="firm-selected">
                                      <div class="firm-numbers">2</div>
                                      <div class="firm-texts">填写企业汇款信息</div>
                                    </div>
                                </div>
                                
                              </div>
                              <div class="frim-bom">
                                <div>选择汇款用途：</div>
                                <div class="frim-checked">
                                  <el-checkbox v-model="checked">线下公转自助认款</el-checkbox>
                                </div>
                              </div>
                            </el-tab-pane>
                            <el-tab-pane label="查询认款申请单" name="second">
                              <div class="frim-infor">
                                <div class="flex-start infor-box">
                                  <div class="flex-start infor-hang">
                                    <div class="infor-text">认款状态：</div>
                                    <el-select class="infor-input" v-model="value" placeholder="请选择">
                                      <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                      </el-option>
                                    </el-select>
                                  </div>
                                  <div class="flex-start">
                                    <div class="infor-text">汇款金额：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                </div>
                                <div class="flex-start infor-box">
                                  <div class="flex-start infor-hang">
                                    <div class="infor-text">汇款公司：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                  <div class="flex-start">
                                    <div class="infor-text">认款申请单号：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                </div>
                                <div class="flex-start infor-box">
                                  <div class="flex-start infor-hang">
                                    <div class="infor-text">申请人：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                  <div class="flex-start">
                                    <div class="infor-text">申请人手机号：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                </div>
                                <div class="flex-start infor-box">
                                  <div class="flex-start infor-hang">
                                    <div class="infor-text">申请时间：</div>
                                    <el-input class="infor-inputs" v-model="input" placeholder="请输入内容"></el-input>
                                    <div class="infor-texts">至</div>
                                    <el-input class="infor-inputs" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                  <div class="flex-start">
                                    <div class="infor-text">订单号：</div>
                                    <el-input class="infor-input" v-model="input" placeholder="请输入内容"></el-input>
                                  </div>
                                  <div>
                                    <div class="infor-btn">查询</div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-end frim-end">导出列表</div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div class="frim-table">
                    <el-table
                    :data="tableData"
                    border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                    :cell-style="{ textAlign: 'center' }"
                    style="width: 100%;text-align: center;">
                        <el-table-column
                        prop="number"
                        label="选择下单资质"
                        width="140">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="编号"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="priac"
                        label="发票抬头"
                        width="160">
                        </el-table-column>
                        <el-table-column
                        prop="how"
                        label="注册电话"
                        width="160">
                        </el-table-column>
                        <el-table-column
                        prop="subtotal"
                        label="纳税人识别号"
                        width="140">
                        </el-table-column>
                        <el-table-column
                        prop="handle"
                        label="开户行"
                        width="140">
                        </el-table-column>
                        <el-table-column
                        prop="who"
                        label="增票资质状态"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="addresss"
                        label="操作"
                        width="90">
                        </el-table-column>
                    </el-table>
                    <div class="flex-between frim-table-bom">
                      <div class="flex-start">
                        <el-checkbox v-model="checked">全部</el-checkbox>
                        <div class="bom-text">已选0个订单，共0元</div>
                      </div>
                      <div>
                        <div class="frim-btn">确认</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        currentPage:1,
        activeName: 'first',
        checked: true,
        input:'',
        ruleForm: {
          name: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入收货人', trigger: 'blur' },
          ],
        },
        tableData: [],
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: ''
      }
  },
  methods:{
      addTicket(){
        this.$router.push({ name: 'AddTicket' })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      handleClick(tab, event) {
        console.log(tab, event);
      }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rightsd{
    width: 87%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .firm-box{
        padding: 10px 20px;
        // border-bottom: 1px solid #d7d7d7;
        border: 1px solid #d7d7d7;
        .el-tabs__nav-wrap::after{
            border: none;
        }
        .el-tabs__item.is-active{
            color: #CD9F49;
        }
        .el-tabs__item:hover{
            color: #CD9F49;
        }
        .el-tabs__active-bar{
            background-color: #CD9F49;
        }
        .firm-top{
          margin: 50px 0;
          .frim-line{
            width: 377px;
            height: 1px;
            background: #999;
            margin: 0 auto;
            
          }
        }
        .firm-select{
          position: absolute;
          top: -12px;
          left: 380px;
          text-align: center;
          .firm-number{
            width: 27px;
            height: 27px;
            line-height: 27px;
            border-radius: 50%;
            color: #fff;
            background: #CD9F49;
            margin: 0 auto;
          }
          .firm-text{
            color: #CD9F49;
          }
          
        }
        .firm-selected{
          position: absolute;
          top: -12px;
          left: 600px;
          text-align: center;
          .firm-numbers{
            width: 27px;
            height: 27px;
            line-height: 27px;
            border-radius: 50%;
            color: #fff;
            background: #999;
            margin: 0 auto;
          }
          .firm-texts{
            color: #999;
          }
        }
        .frim-bom{
          color: #CD9F49;
          padding-bottom: 20px;
          .frim-checked{
            padding: 10px 0 0 20px;
            .el-checkbox__inner{
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            .el-checkbox__inner::after{
              width: 5px;
              height: 10px;
              left: 6px;
              top: 2px;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
              background-color: #CD9F49;
              border-color: #CD9F49;
            }
            .el-checkbox__input.is-checked+.el-checkbox__label{
              color: #CD9F49 !important;
            }
          }
        }
    }
    .frim-infor{
      .infor-box{
        margin: 15px 0;
        width: 100%;
        .infor-hang{
          margin-right: 50px;
        }
        .infor-text{
          width: 120px;
        }
        .infor-input{
          width: 340px;
        }
        .infor-texts{
          margin: 0 10px;
        }
        .infor-inputs{
          width: 152px;
        }
        .infor-btn{
            width: 83px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            margin-left: 15px;
            color: #fff;
            background: #CD9F49;
            border-radius: 5px;
        }
      }
    }
    .frim-end{
      color: #CD9F49;
      margin-right: 25px;
    }
    .frim-table{
      margin: 20px 0;
      .frim-table-bom{
        margin: 20px 15px 0;
        .bom-text{
          margin-left: 15px;
        }
        .frim-btn{
          width: 106px;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          background: #CD9F49;
        }
      }
    }
  }
}
</style>